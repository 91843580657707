<template>
    <div class="body">
      <h1 class="centered">FAQ</h1>
  
      <h2 class="margin-sides">General</h2>
  
      <div v-for="faq in generalFaqs" :key="faq.id" class="faq-item margin-sides">
        <h3 @click="toggleAnswer(faq.id)" class="faq-question">{{ faq.question }}</h3>
        <p v-if="faq.showAnswer" class="faq-answer" v-html="faq.answer"></p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'FAQ',
    data() {
      return {
        generalFaqs: [
          {
            id: 1, 
            question: 'What is SnipitFM?', 
            answer: 'SnipitFM is an app to vote on music polls.',
            showAnswer: false 
          },
          {
            id: 2, 
            question: 'How much does it cost?', 
            answer: "With SnipitFM, it's free to vote on music polls but we will offer paid opportunities to make things more interesting in the future.",
            showAnswer: false 
          },
          {
            id: 3,
            question: 'Who made SnipitFM?',
            answer: 'SnipitFM was designed and developed by <a class="link" href="https://twitter.com/mrdrinkmeyeezys" target="_blank">Marko Calvo-Cruz</a>.',
            showAnswer: false
          },
          {
            id: 4,
            question: 'Why did you create SnipitFM?',
            answer: '100,000 new songs are uploaded to the internet everyday. We wanted to create a system for listening to them all. We hope that SnipitFM helps you find new artists you would not have found any other way.',
            showAnswer: false
          },
          {
            id: 5,
            question: 'When was SnipitFM launched?',
            answer: 'SnipitFM was launched in August 2022.',
            showAnswer: false
          },
          {
            id: 6,
            question: 'When will it be available on Android?',
            answer: 'We don’t have a release date at this time.',
            showAnswer: false
          },
          {
            id: 7,
            question: 'Is SnipitFM private?',
            answer: 'SnipitFM cares intensely about user privacy. Account information is only used to run the app and help you connect with friends. SnipitFM will never sell or share personal information with third-parties, except as described in our <a href="https://snipitfm-policies.vercel.app/" target="_blank">Privacy Policy</a>.',
            showAnswer: false
          },
          {
            id: 8,
            question: 'Is SnipitFM safe?',
            answer: 'SnipitFM is designed to be a safe place to listen to music with friends. Users can only interact through polls and only with people they have a similar music taste with. Unlike apps like Snapchat or Instagram, we do not support messaging between users.',
            showAnswer: false
          },
          {
            id: 9,
            question: 'Where is SnipitFM available?',
            answer: 'SnipitFM is currently only available in some regions. We are growing our server capacity to support more users.',
            showAnswer: false
          }
        ]
      };
    },
    mounted() {
      console.log('FAQ.mounted() - FAQ component mounted.');
    },
    methods: {
      toggleAnswer(id) {
        const faq = this.generalFaqs.find(f => f.id === id);
        if (faq) {
          faq.showAnswer = !faq.showAnswer;
        }
        console.log('FAQ.toggleAnswer() - Toggled answer for ID:', id);
      }
    }
  };
  </script>
  
  <style scoped>
  .body {
    background: #1f1f1f;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
  }
  .centered {
    text-align: center;
  }
  
  .margin-sides {
    margin-left: 1.25em;
    margin-right: 1.25em;
  }
  
  .faq-question {
    cursor: pointer;
    /* // ... add styles for the question headers */
  }
  
  .faq-answer {
    /* // ... styles for the answers */
  }
  
  .faq-answer a {
    /* // styles for the hyperlinks within the answers */
    color: #FF7C26;
  }
.link {
    color: #FF7C26;
  }
  .faq-answer a {
    color: #FF7C26;
}
.link {
    color: #FF7C26;
}
</style>
  


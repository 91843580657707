<template>
  <div id="home" >
    <div class="flex-container">
      <!-- Multi-colored Music Hunt Text -->
      <div class="container">
        <div class="title-container">
          <div class="title" :style="{ top: titleVerticalOffset }">
            <span :style=" { color: getCharacterColor(0)}">S</span>
            <span :style=" { color: getCharacterColor(1)}">n</span>
            <span :style=" { color: getCharacterColor(2)}">i</span>
            <span :style=" { color: getCharacterColor(3)}">p</span>
            <span :style=" { color: getCharacterColor(4)}">i</span>
            <span :style=" { color: getCharacterColor(6)}">t</span>
            <span :style=" { color: getCharacterColor(7)}">F</span>
            <span :style=" { color: getCharacterColor(8)}">M</span>
          </div>
          <div class="subtitle">
            <h2>the 1st music betting app</h2>
          </div>
        </div>
        <!-- Centered Logo -->
        <div class="logo-container">
          <img src="@/assets/snipitfm-logo.png" alt="SnipitFM Logo" />
        </div>
        <!-- Download to App Store Button -->
        <h1> Coming soon </h1>
        <!-- <DownloadButton :link="appLink" class="download-button pulse" /> -->
            </div>
      </div>
    <!-- Footer -->
    <div class="footer">
      <a :href="'mailto:hello@snipit.fm'" class="footer-item" target="_blank"> Email Us </a>
      <a href="/faq" class="footer-item" > FAQ </a>
      <!-- <a href="#" class="footer-item" target="_blank"> Terms </a> -->
      <a href="https://snipitfm-policies.vercel.app/" target="_blank" class="footer-item"> Privacy </a>
      <!-- <a href="#" class="footer-item" target="_blank"> Safety </a> -->
  
    </div>
  </div>
</template>

<script>
//import DownloadButton from '@/components/DownloadButton.vue';


export default {
  name: "HomeView",

  components: {
   // DownloadButton,

  },
  data() {
    return {
      appLink: 'https://testflight.apple.com/join/NlGYHvW1',
      titleVerticalOffset: "20vh",
      musicHuntArray: Array.from("Music Hunt").filter(char => char !== ' '),

      colors: ["#CAFF34","#CC74F5", "#FB31C9", "#FF9C5A", "#CAFF34",],
      footerItems: ["Email Us", "FAQ", "Terms", "Privacy", "Safety"],
    };
  },
  methods: {
    // Helper function to calculate the color of each character in "Music Hunt"
    getCharacterColor(index) {
      return this.colors[index % this.colors.length];
    },
    goToPrivacyPolicy() {
      console.log("goToPrivacyPolicy");
      this.$router.push({ name: "PrivacyPolicy" });
    },
  },
  filters: {
  trimString(value) {
    return value.trim();
  }
}
};
</script>

<style scoped>
#home {
  background: #181818;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will create space between flex-container and footer */
  align-items: center;
}
h1 {
  margin: 0 auto;
}
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 86vh;
  width: 100%;
  
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
  
}
.footer {
  background-color: #333333;
  height: 14vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Rubik', sans-serif;
  spacing: 20px;
  font-size: 16px;
  gap: 2rem;
  color: white;
}
.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0rem;
}
.title {
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 84px;
  line-height: 1;
  
}
.subtitle {
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: white;
  margin: 0;
  
}
h2 {
  margin: 0;
  margin-top: 0.5rem;
}
.logo-container {
  display: flex;
  justify-content: center;
  
}

.download-button {
  align-self: center;
}
.pulse {
   animation: growWiggleShrink infinite 2.5s;
}

@keyframes growWiggleShrink {
    0%, 100% {
        transform: scale(1);
    }
    25%, 75% {
        transform: scale(1.3);
    }
    35% {
        transform: scale(1.3) rotate(5deg);
    }
    40% {
        transform: scale(1.3) rotate(-5deg);
    }
    45% {
        transform: scale(1.3) rotate(5deg);
    }
    50% {
        transform: scale(1.3) rotate(-5deg);
    }
    
    55% {
        transform: scale(1.3) rotate(5deg);
    }
    60% {
        transform: scale(1.3) rotate(-5deg);
    }  
    65% {
        transform: scale(1.3) rotate(eg);
    }

  

}

a {
  color: white;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .title {
    font-size: 54px;
  }
  .subtitle {
    font-size: 15px;
  }
}

</style>


<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
//import HomeView from './components/Home.vue'

export default {
  name: 'App',
  // components: {
  //   HomeView
  // }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

}
</style>
